import actionType from './ActionTypes';

/** ThemeReducer Action (state.theme) */

function setEnableMobileMenu(enableMobileMenu) {
	return {
		type: actionType.SET_ENABLE_MOBILE_MENU,
		enableMobileMenu: enableMobileMenu,
	};
}

function setEnableClosedSidebar(enableClosedSidebar) {
	return {
		type: actionType.SET_ENABLE_CLOSED_SIDEBAR,
		enableClosedSidebar: enableClosedSidebar,
	};
}

/** LWAReducer Actions (state.LWA) */

function AmznLogin(user) {
	return {
		type: actionType.AMZN_LOGIN,
		user: user,
		currentProvider: user._provider,
		isAuth: true,
	};
}

function TriggerAmznLogout() {
	return {
		type: actionType.TRIGGER_AMZN_LOGOUT,
		isAuth: false,
	};
}

function FinishAmznLogout() {
	return {
		type: actionType.FINISH_AMZN_LOGOUT,
		user: {},
		currentProvider: '',
		isAuth: false,
	};
}

/** EntryReducer Actions (state.loggedUser) */

function LoginUser(
	user,
	token,
	accessLevel,
	sellerProfiles,
	needsAdvertisingAPIAuth,
	subscriptionLevel,
	appSumoPlanID,
	hasCampaigns,
	globalPermissions,
	roles
) {
	return {
		type: actionType.LOGIN_SUCCESS,
		user: user,
		token: token,
		accessLevel: accessLevel,
		sellerProfiles: sellerProfiles,
		logged: true,
		needsAdvertisingAPIAuth: needsAdvertisingAPIAuth,
		subscriptionLevel: subscriptionLevel,
		appSumoPlanID: appSumoPlanID,
		newUser: !hasCampaigns,
		globalPermissions:globalPermissions,
		roles:roles
	};
}

function SubmitPromoCode(promoCode, token) {
	return {
		type: actionType.PROMO_CODE_SUBMIT,
		promoCode: promoCode,
		token: token,
	};
}

function LoginFailed(errorMessage) {
	return {
		type: actionType.LOGIN_FAILED,
		error: errorMessage,
	};
}

function LogoutUser() {
	return {
		type: actionType.LOGOUT_SUCCESS,
		user: {},
		accessLevel: -1,
		token: '',
		logged: false,
	};
}

function RegistrationFailed(errorMessage) {
	return {
		type: actionType.REGISTRATION_FAILED,
		error: errorMessage,
	};
}

function SetDefaultSellerId(sellerId) {
	return {
		type: actionType.SET_DEFAULT_SELLER_ID,
		defaultSellerId: sellerId,
	};
}

function LoadDirectories(usersDirectory,storesDirectory,managedStoreFirstDirectory,unmanagedStoreFirstDirectory,allProductsDirectory) {
	return {
		type: actionType.LOAD_DIRECTORIES_SUCCESS,
		usersDirectory: usersDirectory,
		storesDirectory: storesDirectory,
		managedStoreFirstDirectory: managedStoreFirstDirectory,
		unmanagedStoreFirstDirectory: unmanagedStoreFirstDirectory,
		allProductsDirectory : allProductsDirectory

	};
}
function LoadStoresDirectories(storesDirectory) {
	return {
		type: actionType.LOAD_STORES_DIRECTORIES_SUCCESS,
		storesDirectory: storesDirectory,
	};
}

function AuthAdvertisingAPI() {
	return {
		type: actionType.AUTH_ADVERTISING_API_SUCCESS,
		needsAdvertisingAPIAuth: false,
	}
}

function RequireAuthAdvertisingAPI() {
	return {
		type: actionType.AUTH_ADVERTISING_API_REQUIRE,
		needsAdvertisingAPIAuth: true,
	}
}
function UpdateSubscriptionLevel(subscriptionLevel, appSumoPlanID) {
	return {
		type: actionType.UPDATE_SUBSCRIPTION_LEVEL,
		subscriptionLevel: subscriptionLevel,
		appSumoPlanID: appSumoPlanID,
	}
}

/** CartReducer Actions (state.cart) */

function AddToCart(campaign) {
	return {
		type: actionType.ADD_TO_CART,
		campaign: campaign,
		itemId: `${campaign.productASIN}_${campaign.advertisingObjective}`,
		sellerProfileId: campaign.sellerProfileId,
	};
}

function RemoveFromCart(campaigns) {
	return {
		type: actionType.REMOVE_FROM_CART,
		campaigns: campaigns,
	};
}

function ClearCart() {
	return {
		type: actionType.CLEAR_CART,
	};
}

/** CampaignReducer Actions (state.userCampaigns) */

function GetCampaignList(campaignList) {
	return {
		type: actionType.LOAD_CAMPAIGN_LIST_SUCCESS,
		activeCampaigns: campaignList,
	};
}

function UpdateCreditBalance(creditBalance) {
	return {
		type: actionType.CHECK_CREDITS_BALANCE,
		creditBalance: creditBalance,
	};
}

/** SellerProfilesReducer Actions (state.accountOverview.landing) */

function LoadAdProfiles(adProfiles, totalSpend) {
	return {
		type: actionType.LOAD_ADPROFILES_SUCCESS,
		adProfiles,
		totalSpend: totalSpend,
	};
}

function LoadReadyCampaigns(readyCampaigns) {
	return {
		type: actionType.LOAD_READY_CAMPAIGNS_SUCCESS,
		readyCampaigns: readyCampaigns,
	};
}

/** StoreReducer Actions (state.accountOverview.store) */

function LoadStoreData(
	integerID,
	storeOverview,
	storeProducts,
	childProducts,
	parentProducts,
	orphanProducts,
	storeCampaigns,
	unclaimedCampaigns,
	weeklyReport,
	ProductPerformanceReport,
	TargetPerformanceReport,
	StoreOverviewReport,
	StoreOverviewPrimeReport,
	WeeklySalesReport,
	SalesTrendsReport,
	WeeklySearchTermReport,
	CampaignPerformanceReport,
	StoreUserPermission,
	metricData,
	campaignUpdates,
	creditPurchases
) {
	return {
		type: actionType.LOAD_STORE_DATA_SUCCESS,
		storeOverview: storeOverview,
		storeProducts: storeProducts,
		childProducts: childProducts,
		parentProducts: parentProducts,
		orphanProducts: orphanProducts,
		integerID: integerID,
		storeCampaigns: storeCampaigns,
		unclaimedCampaigns: unclaimedCampaigns,
		weeklyReport:weeklyReport,
		ProductPerformanceReport:ProductPerformanceReport,
		TargetPerformanceReport:TargetPerformanceReport,
		StoreOverviewReport:StoreOverviewReport,
		StoreOverviewPrimeReport:StoreOverviewPrimeReport,
		CampaignPerformanceReport:CampaignPerformanceReport,
		StoreUserPermission:StoreUserPermission,
		WeeklySalesReport:WeeklySalesReport,
		SalesTrendsReport:SalesTrendsReport,
		WeeklySearchTermReport:WeeklySearchTermReport,
		metricData: metricData,
		campaignUpdates: campaignUpdates,
		creditPurchases: creditPurchases,
	};
}

function LoadStoreUnitsData(unitsData) {
	return {
		type: actionType.LOAD_STORE_UNITS_DATA_SUCCESS,
		unitsData: unitsData,
	};
}

function LoadStoreSalesData(salesData) {
	return {
		type: actionType.LOAD_STORE_SALES_DATA_SUCCESS,
		salesData: salesData,
	}
}

function LoadStoreAdData(adData) {
	return {
		type: actionType.LOAD_STORE_AD_DATA_SUCCESS,
		advertisingData: adData,
	}
}

/** ProductReducer Actions (state.accountOverview.product) */

function LoadProductData(
	productOverview,
	productCampaigns,
	metricData,
	keywordTableData,
	granularKeywordData,
	topKeywords,
	impressionGeneratingKeywords,
	campaignUpdates,
	creditPurchases
) {
	return {
		type: actionType.LOAD_PRODUCT_DATA_SUCCESS,
		productOverview: productOverview,
		productCampaigns: productCampaigns,
		metricData: metricData,
		keywordTableData: keywordTableData,
		granularKeywordData: granularKeywordData,
		topKeywords: topKeywords,
		impressionGeneratingKeywords: impressionGeneratingKeywords,
		campaignUpdates: campaignUpdates,
		creditPurchases: creditPurchases,
	};
}

function LoadProductPerformanceData(asinData, convertingKeywords, impressionKeywords, allKeywords, campaignData) {
	return {
		type: actionType.LOAD_PRODUCT_PERFORMANCE_SUCCESS,
		performanceData: asinData,
		convertingKeywords: convertingKeywords,
		impressionKeywords: impressionKeywords,
		allKeywords: allKeywords,
		campaignData: campaignData,
	}
}

function LoadProductMRPData(mrpData) {
	return {
		type: actionType.LOAD_PRODUCT_MRP_DATA_SUCCESS,
		mrpData: mrpData,
	};
}

function LoadParentProductRankData(keywordData) {
	return {
		type: actionType.LOAD_PARENT_PRODUCT_RANK_DATA_SUCCESS,
		keywordData: keywordData,
	};
}

function LoadProductBleederKW(keywords) {
	return {
		type: actionType.LOAD_PRODUCT_BLEEDER_KW_SUCCESS,
		bleederKeywords: keywords,
	};
}

function LoadProductIdleKW(keywords) {
	return {
		type: actionType.LOAD_PRODUCT_IDLE_KW_SUCCESS,
		idleKeywords: keywords,
	};
}

/** CampaignReducer Actions (state.accountOverview.campaign) */

function LoadCampaignData(
	campaignOverview,
	campaignType,
	keywords,
	targetedProducts,
	metricData
) {
	return {
		type: actionType.LOAD_CAMPAIGN_DATA_SUCCESS,
		campaignOverview: campaignOverview,
		campaignType: campaignType,
		keywords: keywords,
		targetedProducts: targetedProducts,
		metricData: metricData,
	};
}

/** MarketAnalysisReducer Actions (state.marketAnalysis) */
function AddAsinForMA(asin) {
	return {
		type: actionType.ADD_ASIN_FOR_MA,
		asin: asin,
	};
}

function LoadAsinMA(marketAnalysis) {
	return {
		type: actionType.LOAD_ASIN_MA_DATA,
		analysisData: marketAnalysis,
	};
}

function RemoveAsinMA(asin) {
	return {
		type: actionType.REMOVE_MA,
		asin: asin,
	};
}

function SetAutofillASIN(asin) {
	return {
		type: actionType.SET_AUTOFILL_ASIN,
		asin: asin,
	};
}

/** CampaignBuilderReducer Actions (state.campaignBuilder) */
function SetAutofillFields(autofill) {
	return {
		type: actionType.SET_AUTOFILL_FIELD,
		autofill: autofill,
	};
}

function ResetAutofillFields() {
	return {
		type: actionType.RESET_AUTOFILL_FIELD,
	};
}

/** ProfileReducer (state.profile) */
function LoadContentAgencies(contentAgencies) {
	return {
		type: actionType.LOAD_CONTENT_AGENCIES_SUCCESS,
		contentAgencies: contentAgencies,
	};
}

function LoadProjects(projects) {
	return {
		type: actionType.LOAD_PROJECTS_SUCCESS,
		projects: projects,
	};
}

function LoadLeadGenJobs(leadGenJobs) {
	return {
		type: actionType.LOAD_LEAD_GEN_JOBS_SUCCESS,
		leadGenJobs: leadGenJobs,
	};
}

function LoadProductCategories(productCategories) {
	return {
		type: actionType.LOAD_PRODUCT_CATEGORIES_SUCCESS,
		productCategories: productCategories,
	};
}
function LoadAmaProdCategories(AmazonProductCategories){
	return {
		type: actionType.LOAD_AMAZON_PRODUCT_CATEGORIES_SUCCESS,
		AmazonProductCategories: AmazonProductCategories,
	};
}
function LoadAmazonDomains(amazonDomains){
	return {
		type: actionType.LOAD_AMAZON_DOMAINS_SUCCESS,
		amazonDomains: amazonDomains,
	};
}

function LoadSellerPartnerList(sellerPartnerList){
	return {
		type: actionType.LOAD_SELLER_PARTNER_LIST_SUCCESS,
		sellerPartnerList: sellerPartnerList,
	};
}

function LoadCategoryProductsSellers(products, sellers) {
	return {
		type: actionType.LOAD_CATEGORY_PRODUCTS_SELLERS_SUCCESS,
		categoryProducts: products,
		categorySellers: sellers,
	};
}

function LoadResearchProductCategoryData(researchProductCategoryData) {
	return {
		type: actionType.LOAD_RESEARCH_PRODUCT_CATEGORY_DATA_SUCCESS,
		researchProductCategoryData: researchProductCategoryData,
	};
}

function LoadAppSumoDetails(addKeywordTrackingHubSpotPaymentDetails) {
	return {
		type: actionType.LOAD_APPSUMO_PAYEMENT_DETAILS_SUCCESS,
		addKeywordTrackingHubSpotPaymentDetails: addKeywordTrackingHubSpotPaymentDetails,		
	};
}

export {
	setEnableMobileMenu,
	setEnableClosedSidebar,

	AmznLogin,
	TriggerAmznLogout,
	FinishAmznLogout,

	LoginUser,
	LoginFailed,
	LogoutUser,
	RegistrationFailed,
	SetDefaultSellerId,
	LoadDirectories,
	LoadStoresDirectories,
	AuthAdvertisingAPI,
	RequireAuthAdvertisingAPI,
	UpdateSubscriptionLevel,

	AddToCart,
	RemoveFromCart,
	ClearCart,

	GetCampaignList,
	UpdateCreditBalance,

	LoadAdProfiles,
	LoadReadyCampaigns,

	LoadStoreData,
	LoadStoreUnitsData,
	LoadStoreSalesData,
	LoadStoreAdData,

	LoadProductData,
	LoadProductPerformanceData,
	LoadProductMRPData,
	LoadParentProductRankData,
	LoadProductBleederKW,
	LoadProductIdleKW,

	LoadCampaignData,

	AddAsinForMA,
	LoadAsinMA,
	RemoveAsinMA,
	SetAutofillASIN,

	SetAutofillFields,
	ResetAutofillFields,

	LoadContentAgencies,
	LoadProjects,
	LoadProductCategories,
	LoadCategoryProductsSellers,
	LoadResearchProductCategoryData,
	LoadLeadGenJobs,
	LoadAmaProdCategories,
	LoadAmazonDomains,
	LoadAppSumoDetails,
	LoadSellerPartnerList,
};
