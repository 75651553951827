import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const TablePagination = ({ pageSize, totalRecords, onPageChange, currentPage }) => {
  const totalPages = Math.ceil(totalRecords / pageSize);
  const startRecord = currentPage * pageSize + 1;
  const endRecord = Math.min((currentPage + 1) * pageSize, totalRecords);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      onPageChange({ currentPage: currentPage - 1, pageSize });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      onPageChange({ currentPage: currentPage + 1, pageSize });
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '12px', fontWeight: 'normal', fontSize: '15px' }}>
        <FontAwesomeIcon
            icon={faChevronLeft}
            onClick={handlePreviousPage}
            style={{ 
                cursor: currentPage === 0 ? 'not-allowed' : 'pointer',
                opacity: currentPage === 0 ? 0.5 : 1,
                fontSize: '16px',
            }}
      />

      <span>{`Page ${currentPage + 1} of ${totalPages}`}</span>
    
      <FontAwesomeIcon
        icon={faChevronRight}
        onClick={handleNextPage}
        style={{ 
          cursor: currentPage === totalPages - 1 ? 'not-allowed' : 'pointer',
          opacity: currentPage === totalPages - 1 ? 0.5 : 1,
          fontSize: '16px',
        }}
      />
    </div>
  );
};

export default TablePagination;