import actionType from '../Actions/ActionTypes';


const initialState = {
    user: {},
    token: '',
    accessLevel: -1,
    logged: false,
    sellerProfileIdList: [],
    defaultSellerId: '',
    status: '',
    error: '',
    creditBalance: 0,
    newUser: true,
    needsAdvertisingAPIAuth: true,
    subscriptionLevel: 0,
    appSumoPlanID: "",
    globalPermissions:"",
    roles:"",
};


function EntryReducer(state = initialState, action) {
    switch(action.type) {
        case actionType.LOGIN_BEGIN:
            return {
                ...state,
                status: "loading",
                newUser: action.newUser,
                // newUser: true,
            }
        case actionType.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                token: action.token,
                accessLevel: action.accessLevel,
                // accessLevel: 2,
                logged: action.logged,
                sellerProfileIdList: action.sellerProfiles,
                defaultSellerId: action.sellerProfiles[0],
                needsAdvertisingAPIAuth: action.needsAdvertisingAPIAuth,
                subscriptionLevel: action.subscriptionLevel,
                appSumoPlanID: action.appSumoPlanID,
                globalPermissions: action.globalPermissions,//
                roles: action.roles,
                newUser: action.newUser,
                // newUser: true,
                status: '',
            }
        case actionType.LOGIN_FAILED:
            return {
                ...state,
                status: 'LOGIN_FAILED',
                error: action.error,
            }
        case actionType.REGISTRATION_FAILED:
            return {
                ...state,
                status: 'REGISTRATION_FAILED',
                error: action.error,
            }
        case actionType.LOGOUT_BEGIN:
            return {
                ...state,
                status: "loading",
            }

        case actionType.LOGOUT_SUCCESS:
            return {
                ...initialState,
                user: action.user,
                token: action.token,
                logged: action.logged,
            }
        case actionType.LOGOUT_FAILED_NO_CONNECTION:
            return {
                ...state,
                status: 'LOGOUT_FAILED',
                error: "We are having trouble connecting to the server. Please try again later.",
            }
        case actionType.LOGOUT_FAILED_UNKNOWN_ERROR:
            return {
                ...state,
                status: 'LOGOUT_FAILED',
                error: "We're sorry, but we're not sure what went wrong. Please try again later.",
            }
        case actionType.CONFIRM_TUTORIAL:
            return {
                ...state,
                newUser: false,
            }
        case actionType.SET_DEFAULT_SELLER_ID:
            return {
                ...state,
                defaultSellerId: action.defaultSellerId,
            }
        case actionType.CHECK_CREDITS_BALANCE:
            return {
                ...state,
                creditBalance: action.creditBalance,
            }
        case actionType.STORE_AUTH_SUCCESS:
            return {
                ...state,
                newUser: false,
            }
        case actionType.AUTH_ADVERTISING_API_SUCCESS:
            return {
                ...state,
                needsAdvertisingAPIAuth: action.needsAdvertisingAPIAuth,
            }
        case actionType.UPDATE_SUBSCRIPTION_LEVEL:
            return {
                ...state,
                subscriptionLevel: action.subscriptionLevel,
                appSumoPlanID: action.appSumoPlanID ? action.appSumoPlanID : state.appSumoPlanID,
            }
        default:
            break;
    }

    return state;
}

export default EntryReducer;
