import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Card } from 'reactstrap';
import { connect } from 'react-redux';
import GetNotifications from '../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/PartnerUpdateLog/GetNotifications';
import FevMessageService from '../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/PartnerUpdateLog/FevMessageService';
import ArchiveMessageService from '../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/PartnerUpdateLog/ArchiveMessageService';
import ReadUpdateIndicatorService from '../../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/PartnerUpdateLog/ReadUpdateIndicatorService';
import PCSelectedStarIcon from '../../../../../../../designUtils/Storeoverview/PartnerLogs/PCSelectedStarIcon.svg';
import PCStarIcon from '../../../../../../../designUtils/Storeoverview/PartnerLogs/PCStarIcon.svg';
import PCArchiveIcon from '../../../../../../../designUtils/Storeoverview/PartnerLogs/PCArchiveIcon.svg';
import PCMarkAsReadIcon from '../../../../../../../designUtils/Storeoverview/PartnerLogs/PCMarkAsReadIcon.svg';
import TablePagination from '../../../../../../Components/Pagination/TablePagination'
import Unarchive from '../../../../../../../designUtils/Storeoverview/PartnerLogs/Unarchive.svg';
import MarkAsUnread from '../../../../../../../designUtils/Storeoverview/PartnerLogs/MarkAsUnread.svg';
import LoadingOverlay from 'react-loading-overlay';
import Loader from "react-loaders";

function PartnerUpdateLogMainFile(props) {
  const [selectedTab, setSelectedTab] = useState('all');
  const [notificationDetails, setNotificationDetails] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [searchNoti, setSearchNoti] = useState('');
  const [notiCount, setNotiCount] = useState('0');
  const [actionNotiCount, setActionNotiCount] = useState('0');
  const [userFullName, setUserFullName] = useState('');
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [archiveNotiCount, setArchiveNotiCount] = useState('0');


  useEffect(() => {
    setLoading(true)
    // Fetch initial notifications data
    GetNotifications(props.token).then((res) => {
      
      if (res) {
        
        console.log(res)
        setNotiCount(res.noticount);
        setUserFullName(res.notilist[0]?res.notilist[0].fullName:'');
        setNotificationDetails(res.notilist);
        const archiveCount = res.notilist.filter((noti) => noti.Archive === true && noti.Read_Indicator === false).length;
        setArchiveNotiCount(archiveCount);
        const actionNotiCount =res.notilist.filter((noti)=>noti.Notificationtype !== null && noti.Read_Indicator === false).length
        setActionNotiCount(actionNotiCount)
        filterNotifications(res.notilist, selectedTab, searchNoti); // Filter on load
        setLoading(false)
      }
    });
  }, []);

  // Create a filter map for each tab
  const filterMap = {
    archive: (noti) => noti.Archive === true,
    favourite: (noti) => noti.Favourite === true &&noti.Archive === false,
    actionRequired: (noti) => noti.Notificationtype !== null &&noti.Archive === false,
    all: (noti) => true &&noti.Archive === false, 
  };

  // Filter notifications based on the current tab and search query
  const filterNotifications = (notilist, tab, searchQuery) => {
    let filteredData = notilist || [];
    // Filter based on search query
    if (searchQuery) {
      filteredData = filteredData.filter((noti) =>
        noti.Message && noti.Message.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply tab-based filter using the filterMap object
    filteredData = filteredData.filter(filterMap[tab]);

    setFilteredNotifications(filteredData);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab); 
    setCurrentPage(0);
    filterNotifications(notificationDetails, tab, searchNoti); // Re-filter notifications
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchNoti(query);
    filterNotifications(notificationDetails, selectedTab, query); // Filter based on search
  };
  const handlePageChange = ({ currentPage, pageSize }) => {
    setCurrentPage(currentPage);
    setPageSize(pageSize);
  };
  const handleFevMessage = (notifi) => {
    FevMessageService(props.token, { id: notifi.id }).then((res) => {
      if (res) {
        setNotificationDetails(res.fevMessage.notilist);
        filterNotifications(res.fevMessage.notilist, selectedTab, searchNoti); // Re-filter after updating favourite
      }
    });
  };

  const handleArchiveMessage = (notifi) => {
    let data={id: notifi.id}
    ArchiveMessageService(props.token, data).then((res) => {
      if (res) {
        console.log(res)
        setNotificationDetails(res.archiveMessage.notilist);
        filterNotifications(res.archiveMessage.notilist, selectedTab, searchNoti); // Re-filter after archiving
        if(notifi.Read_Indicator==false){
          if(selectedTab==='actionRequired'){
            setActionNotiCount((prevCnt) => prevCnt - 1);
          }
          else{
            setNotiCount((prevCnt) => prevCnt - 1)
          }
          setArchiveNotiCount((prevCnt)=>prevCnt+1)
        }
        else{
          if(selectedTab==='actionRequired'){
            setActionNotiCount((prevCnt) => prevCnt - 1);
          }
          else{
            setNotiCount((prevCnt) => prevCnt - 1)
          }
          setArchiveNotiCount((prevCnt)=>prevCnt-1)
        }

      }
    });
  };

  const handleRedirect = (notifi,flag) => {
        if(flag ==1 &&notifi.redirectTo){
          const data = { id: notifi.id };
          ReadUpdateIndicatorService(props.token, data).then((res) => {
            if (res) {
              setNotificationDetails(res.readUpdateIndicator.notilist);
              setActionNotiCount((prevCnt) => prevCnt - 1);
              filterNotifications(res.readUpdateIndicator.notilist, selectedTab, searchNoti); // Re-filter after marking as read
            }
          });
      
        const baseUrl = window.location.origin;
        window.location.href = `${baseUrl}${notifi.redirectTo.startsWith('/') ? notifi.redirectTo : '/' + notifi.redirectTo}`;
        }
        else{
          const data = { id: notifi.id };
          ReadUpdateIndicatorService(props.token, data).then((res) => {
            if (res) {
              setNotificationDetails(res.readUpdateIndicator.notilist);
              filterNotifications(res.readUpdateIndicator.notilist, selectedTab, searchNoti); // Re-filter after marking as read
            }
            if(notifi.Read_Indicator==false){
              if(selectedTab==='actionRequired'){
                setActionNotiCount((prevCnt) => prevCnt - 1);
              }
              else if(selectedTab==='archive'){
                setArchiveNotiCount((prevCnt)=>prevCnt-1)
              }
              else{
                setNotiCount((prevCnt) => prevCnt - 1)
              }
            }
            else{
              if(selectedTab==='actionRequired'){
                setActionNotiCount((prevCnt) => prevCnt + 1);
              }
              else if(selectedTab==='archive'){
                setArchiveNotiCount((prevCnt)=>prevCnt+1)
              }
              else{
                setNotiCount((prevCnt) => prevCnt + 1)
              }
            }

          })
        }
       
   
  };
  const startIndex = currentPage * pageSize;
  const paginatedNotifications = filteredNotifications.slice(startIndex, startIndex + pageSize);
  return (
    <Fragment >
       
      <Card className="card-of-problemarea mt-5 pt-5 pl-3 pr-3 pb-3">
        <Row>
          <Col md={8}>
            <h5><b>Hello, {userFullName}</b></h5>
            <p className="large-font"><b>{new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</b></p>
          </Col>
          <Col md={4}>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                style={{ width: '98%' }}
                value={searchNoti}
                onChange={handleSearchChange}
              />
            </div>
          </Col>
        </Row>
        <Row>
        <Col md={12} className='mr-3 text-start'>
        <Row>
        <Col md={8} className="mt-2 d-flex">
          <h6 className='mr-3'  onClick={() => handleTabClick('favourite')}>
            <img src={selectedTab === 'favourite' ? PCSelectedStarIcon : PCStarIcon} alt="Favourite Icon" className="star-icon" />
          </h6>
          <h6 onClick={() => handleTabClick('actionRequired')}>
            <span className={`${selectedTab === 'actionRequired' ? 'selected-action' : 'not-selected-action'} mr-5 ml-5`}>
              {/* <b>Action Required</b><b className="messagenum">{actionNotiCount}</b> */}
              <b>Action Required</b>{actionNotiCount > 0 && <b className="messagenum">{actionNotiCount}</b>}

            </span>
          </h6>
          <h6 onClick={() => handleTabClick('all')}>
            <span className={`${selectedTab === 'all' ? 'selected-action' : 'not-selected-action'}`}>
              {/* <b>Notifications</b><b className="messagenum">{notiCount}</b> */}
              <b>Notifications</b>{notiCount > 0 && <b className="messagenum">{notiCount}</b>}
            </span>
          </h6>
          <h6 className='ml-5' onClick={() => handleTabClick('archive')}>
            <span className={`${selectedTab === 'archive' ? 'selected-action' : 'not-selected-action'}`}>
              {/* <b>Archived</b><b className="messagenum">{archiveNotiCount}</b> */}
              <b>Archived</b>{archiveNotiCount > 0 && <b className="messagenum">{archiveNotiCount}</b>}

            </span>
          </h6>
        </Col>
        <Col md={4} className={`mb-4 mt-1 d-flex justify-content-end pagination-item`}>
          <TablePagination 
            pageSize={pageSize}
            totalRecords={filteredNotifications.length}
            onPageChange={handlePageChange}
            // pageNums={pageNums}
            currentPage={currentPage}
          />   
          </Col>
        </Row>
        </Col>
      </Row>
      </Card>
      <LoadingOverlay
      className='mt-2'
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: '#f1f4f6',
              opacity: 1,
            }),
            content: (base) => ({
              ...base,
              color: '#000',
            }),
          }}
          spinner={<Loader active type="ball-pulse" />}
          text='Loading your Tasks...'
      >
      <Card className="card-of-problemarea p-3 ">
        <>
          {paginatedNotifications && paginatedNotifications.map((notifi, index) => {
            return (
              <Row key={index} className="row-hover-effect notification-item">
                <Col md={1} className="pt-1">
                  <h6 style={{ cursor: 'pointer' }}>
                    <img
                      onClick={() => handleFevMessage(notifi)}
                      src={notifi.Favourite === true ? PCSelectedStarIcon : PCStarIcon}
                      alt="Icon"
                      className="star-icon"
                    />
                  </h6>
                </Col>
                <Col md={8} onClick={() => handleRedirect(notifi,1)}>
                  <Row>
                    <Col md={3} className="pt-0">
                      <h6 className={notifi.Read_Indicator === false ? 'bold-text' : 'normal-text'}>{notifi.createdAtFormated}</h6>
                    </Col>
                    <Col md={9} className="pt-0">
                      <h6 dangerouslySetInnerHTML={{ __html: notifi.Message }} className={notifi.Read_Indicator === false ? 'bold-text' : 'normal-text'}></h6>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <Row>
                    <Col md={12} className="pt-1 d-flex justify-content-end archive-item">
                      {selectedTab !== 'archive' ? (
                        <h6 className="mark-as-read-icon" onClick={() => handleArchiveMessage(notifi)}>
                          <img src={PCArchiveIcon} alt="Archive Icon" className="archive" title="Archive"/>
                        </h6>
                      ) : (
                        <h6 className="mark-as-read-icon" onClick={() => handleArchiveMessage(notifi)}>
                          <img src={Unarchive} alt="Unarchive Icon" className="archive" title="Unarchive"/>
                        </h6>
                      )} 
                      {!notifi.Read_Indicator && (
                        <h6 className="ml-4 mark-as-read-icon" onClick={() => handleRedirect(notifi,2)}>
                          <img src={PCMarkAsReadIcon} alt="Mark as Read Icon" className="mark-as-read"  title="Mark as read"/>
                        </h6>
                      )}
                      {notifi.Read_Indicator && (
                        <h6 className="ml-4 mark-as-read-icon" onClick={() => handleRedirect(notifi,2)}>
                          <img src={MarkAsUnread} alt="Mark as Read Icon" className="mark-as-read"  title="Mark as Unread"/>
                        </h6>
                          )}
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <hr className="line-pg p-0 m-0"></hr>
                </Col>
              </Row>
            );
          })}
        </>
      </Card>
      </LoadingOverlay>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    globalPermissions: state.loggedUser.globalPermissions,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    childProducts: state.accountOverview.store.childProducts,
    parentProducts: state.accountOverview.store.parentProducts,
    adProfiles: state.accountOverview.landing.adProfiles,
    roles: state.loggedUser.roles
  };
};

export default connect(mapStateToProps)(PartnerUpdateLogMainFile);
